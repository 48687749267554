import { Image } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'

import Header from '../DriverApplicationPage/components/Header'

const NotFoundPage = () => (
  <div className="flex h-screen flex-col">
    <Header />
    <main className="flex-grow overflow-auto">
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <Image
            radius="md"
            h={450}
            w="auto"
            fit="contain"
            src="/images/404-truck-no-background.png"
            alt="truck404"
          />
          <p className="mt-4 text-gray-600">
            Sorry, we couldn't find the page you're looking for.
          </p>
          <a href="/">
            <Button text="Go Home" className="mt-6" />
          </a>
        </div>
      </div>
    </main>
  </div>
)

export default NotFoundPage
